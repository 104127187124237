<template>
  <div class="special-page">
    <el-breadcrumb separator-class="el-icon-right">
      <el-breadcrumb-item :to="{ path: '/admin/dashboard' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>内容管理</el-breadcrumb-item>
      <el-breadcrumb-item>教学内容管理</el-breadcrumb-item>
      <el-breadcrumb-item>专题管理</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="page-content">
      <div class="content-header">
        <el-button type="primary" @click="add">添加专题</el-button>
        <el-button type="danger" @click="back">返回</el-button>
      </div>
      <div class="table-area">
        <el-table :data="pageData" border>
          <el-table-column fixed prop="id" label="ID" width="70"></el-table-column>
          <el-table-column prop="m_id" label="唯一ID" width="120"></el-table-column>
          <el-table-column prop="title" label="专题名称"></el-table-column>
          <el-table-column prop="block_num" label="显示分类数量"></el-table-column>
          <el-table-column prop="campus_title" label="归属校区"></el-table-column>
          <el-table-column fixed="right" label="操作" width="130">
            <template slot-scope="scope">
              <el-button type="text" size="small" @click="edit(scope.row.m_id)">编辑</el-button>
              <el-button type="text" size="small" @click="del(scope.row.m_id)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Special',
  data () {
    return {
      pageData: []
    }
  },
  created () {
    this.getPageData()
  },
  methods: {
    async getPageData () {
      const { data: res } = await this.$http.get('/special-list')
      if (res.code === 200) {
        this.pageData = res.data
      } else {
        this.$message.error(res.msg)
      }
    },
    add () {
      this.$router.push('/admin/content/courses/special/add')
    },
    edit (mid) {
      this.$router.push('/admin/content/courses/special/edit/' + mid)
    },
    back () {
      this.$router.push('/admin/content/courses')
    },
    async del (mid) {
      const { data: res } = await this.$http.delete('/special', { params: { mid: mid } })
      if (res.code === 200) {
        this.$message.success(res.msg)
        await this.getPageData()
      } else {
        this.$message.error(res.msg)
      }
    }
  }
}
</script>

<style scoped>
.page-content{
  margin-top: 30px;
}
.table-area{
  margin-top: 30px;
}
</style>
